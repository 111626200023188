import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Contribution Workflow'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Contribution Workflow`}</h1>
    <p>{`The project uses `}<a parentName="p" {...{
        "href": "https://nodejs.org"
      }}>{`Node.js`}</a>{` `}<strong parentName="p">{`v14.x LTS`}</strong>{`.`}</p>
    <p>{`This is a monorepo maintained with `}<a parentName="p" {...{
        "href": "https://lerna.js.org"
      }}>{`Lerna`}</a>{`.`}</p>
    <h2>{`Setting up`}</h2>
    <p>{`To install repository dependencies and bootstrap repository packages:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install
`}</code></pre>
    <p>{`Post-installation, the packages source code is compiled.`}</p>
    <h2>{`Compiling`}</h2>
    <p>{`The packages interdependencies, testing, the playground, and the website use the
compiled/transpiled CommonJS code of the packages, not the actual source code.`}</p>
    <p>{`So the packages need to be compiled for the packages dependencies, testing, and
the applications to work.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run compile-commonjs
`}</code></pre>
    <p>{`The UMD compiled code is only required when the project is to be released.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run compile-umd
`}</code></pre>
    <h2>{`Testing`}</h2>
    <p><a parentName="p" {...{
        "href": "https://facebook.github.io/jest"
      }}>{`jest`}</a>{`, and `}<a parentName="p" {...{
        "href": "https://github.com/testing-library/react-testing-library"
      }}>{`@testing-library/react`}</a>{`
are used for tools and components unit testing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run test # test once
npm run test-watch # test with watcher
npm run test-coverage # test with coverage
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://eslint.org"
      }}>{`ESLint`}</a>{` and `}<a parentName="p" {...{
        "href": "https://github.com/DavidAnson/markdownlint"
      }}>{`markdownlint`}</a>{`
are used for linting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`# lint all JS/TS/MD files
npm run lint

# JavaScript/TypeScript linting
npm run lint-js # lint
npm run lint-js-fix # fix lint issues

# Markdown linting
npm run lint-md # lint
npm run lint-md-fix # fix lint issues
`}</code></pre>
    <p>{`For a continuous integration environment, the installation, setup, linting, and
testing processes can be executed with:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run ci
`}</code></pre>
    <h2>{`Git commit messages`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.conventionalcommits.org/en/v1.0.0"
      }}>{`Conventional Commits v1.0.0`}</a>{` is
used for commit message format along with the
`}<a parentName="p" {...{
        "href": "https://github.com/commitizen/cz-conventional-changelog"
      }}>{`Conventional Changelog specification`}</a>{`.`}</p>
    <p>{`An interactive git commit message guide is already setup to ensure the convention
is followed every time there is a new commit.`}</p>
    <h2>{`Releasing`}</h2>
    <p>{`The following conditions are required to release the project:`}</p>
    <ul>
      <li parentName="ul">{`Current git branch is `}<inlineCode parentName="li">{`main`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Local branch is synced with remote branch.`}</li>
      <li parentName="ul">{`Git working directory is clean.`}</li>
      <li parentName="ul">{`User is located at project root path.`}</li>
      <li parentName="ul">{`Lints and tests must pass to prevent issues.`}</li>
    </ul>
    <p>{`Then the release can be started:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run release
`}</code></pre>
    <blockquote>
      <p parentName="blockquote">{`As of writing, Lerna v3.22.1 has an issue with the commit message linter so
the process gets blocked halfway. The solution is to wait a minute until this
happens and press `}<inlineCode parentName="p">{`Ctrl+C`}</inlineCode>{` to skip the linter.`}</p>
    </blockquote>
    <p>{`It will execute the following processes:`}</p>
    <ul>
      <li parentName="ul">{`Bootstrap packages.`}</li>
      <li parentName="ul">{`Compile the code.`}</li>
      <li parentName="ul">{`Run linter.`}</li>
      <li parentName="ul">{`Run tests.`}</li>
      <li parentName="ul">{`Lerna publish.`}</li>
    </ul>
    <p>{`Lerna will take care of publishing to NPM and releasing in GitHub.`}</p>
    <p>{`To update the changelog after release:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm run changelog
git add CHANGELOG.md
git commit -m "chore: changelog"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      